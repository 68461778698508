._avantage-header {
  text-align: center;
  font-size: 42px;
  padding-top: 10rem;
  padding-bottom: 25px;
  line-height: 40px;
  color: #454e99;
  width: 55rem;
  margin: auto;

  ._avantage-header-description {
    font-size: 15px;
    line-height: 1.2;
    color: #000;
    margin: 17px 35px;
  }
}

._avantage-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 5px;
  text-align: center;
  position: relative;
  top: 17%;
  padding: 45px 50px;

  &:before {
    content: "";
    background-image: url("../../app/shared/assets/images/icons/citation.png");
    background-size: contain;
    position: relative;
    top: -10px;
    padding: 60px;
    background-repeat: no-repeat;
  }

  ._avantage-card-number {
    color: #52ae99;
    margin-top: 20px;
  }

  ._avantage-card-title {
    font-size: 25px;
  }
}

._avantage-wrapper {
  padding-left: 8rem;
  padding-right: 4rem;
}

._avantage-card-image {
  width: 700px;
  height: 380px;
}

._avantage-card-image--left,
._avantage-card-image--right {
  margin-right: 8rem;
}

._leftCard {
  position: "relative";
  left: -135px;
}

._rightCard {
  position: "relative";
  left: 40px;
}

@media only screen and (max-width: 959px) {
  ._leftCard,
  ._rightCard {
    position: "relative";
    left: 0;
  }

  ._avantage-header {
    font-size: 34px;
    width: 100%;
    margin-top: 5rem;
  }

  ._avantage-card {
    padding: 20px 20px;
    width: 100%;
    margin-top: 35px;

    &:before {
      padding: 45px;
    }

    ._avantage-card-title {
      font-size: 25px;
    }
  }

  ._avantage-wrapper {
    padding-right: 2rem;
    padding-left: 3rem;

    ._avantage-card-image {
      display: none;
    }

    ._avantage-card-image--left,
    ._avantage-card-image--right {
      margin-right: 0;
    }
  }
}
