@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap');

* {
  outline: none !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 74.5%;
  font-family: Muli, Mada, Helvetica Neue, Arial, sans-serif;
  background-color: #fff;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body {
  margin: 0;
  font-family: 'Mada', 'sans-serif';
}

// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }

.rs-table-scrollbar-horizontal {
  display: none;
}

@import "./modules/exercise-treasury.scss";
@import "./modules/exercice-commande.scss";
@import "./modules/exercice-devis.scss";
@import "./modules/statut-button.scss";
@import "./modules/exercise-menu.scss";
@import './modules/accountant-layout.scss';
@import './modules/sign-up.scss';
@import './modules/login-slider.scss';
@import './modules/modal-import-folder.scss';
@import './modules/navbar.scss';
@import './modules/main.header.scss';
@import './modules/advantages.scss';
@import './modules/offres.scss';
@import './modules/fonctionnalities.scss';
@import './modules/contact.scss';
@import './modules/animation.scss';
@import './modules/buttons.theme.scss';
@import './modules/custom.theme.scss';
@import './modules/footer.scss';
@import './modules/styles.theme.scss';
@import './modules/tables.scss';
@import './modules/themes.scss';
@import './modules/toast.css';
@import './modules/input-field.scss';
@import './modules/modal.scss';
@import './modules/app.scss';
@import 'tailwind.css';
@import "./modules/cash-in.scss"
