/* ul{
    list-style-type: none;
    display: flex;
    flex-direction: row;
} */

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 65px;
  align-items: center;
  font-family: 'Roboto';
}

.li {
  padding-right: 30px;
  padding-left: 30px;
}
.navbarButton {
  height: 39px;
  width: 160px;
  border-radius: 7px;
  margin-right: 15px;
  background-color: #454e99;
  border: none;
  color: white;
}

.navbarButton1 {
  height: 39px;
  width: 160px;
  border-radius: 7px;
  border: none;
  background-color: #52ae99;
  color: white;
}
.logoPgfacile {
  width: 45px;
}

#menu-toggle:checked + #menu {
  display: block;
}
ul {
  list-style-type: none;
}
ul {
  list-style-type: none;
}
.disposition {
  display: flex;
  margin: 0 4rem;
  justify-content: space-between;
  height: inherit;
}

@media (max-width: 1024px) {
  .responsive {
    display: none;
  }
}

.content {
  // border: 1px solid black;
  // width: 50%;
  // margin: auto 5rem;
}
.emplacement {
  // border: 1px solid black;
  // margin: 25rem 5rem;
}
