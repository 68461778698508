.dashbord-root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font: normal normal medium;
  color: #5b5b5b;
}

.comptable-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15rem auto;

  .comptable-wrapper-main--title {
    font-size: 29px;
    width: 300px;
    margin: 12px auto;
    font-weight: 200;
  }
}
