
.color-text {
  color: #454e99;
}
.selected-customer, .input--mod {
  border: 1px solid #e6e2e2;
  padding: 10px;
  border-radius: 5px;
  margin-top: 9px;
  display: flex;
  align-items: center;
}

.textarea--mod {
  height: 190px;
  border: 1px solid #d9d9d9;
  padding: 15px;
  font-size: 13px;
  border-radius: 5px;
}

.selected-customer--mod {
  background-color: #f7f7fa;
  height: 190px;
  padding: 10px;
  border-radius: 3px;
}
.label-color {
  color: #93ccbf;
  font-size: 16px;
}
.time-input-style {
  background-image: url('../../app/shared/assets/images/icons/clock.png');
  background-repeat: no-repeat;
  background-position: 95% center;
}
.date-input-style {
  background-image: url('../../app/shared/assets/images/icons/calendarIcon.svg');
  background-repeat: no-repeat;
  background-position: 95% center;
}

.btn-custom {
  border-radius: 4px;
  position: relative;
  top: 2rem;
}

.btn-cancel {
  border: 0;
  background-color: #93ccbf;
  color: white;
}

.btn-draft {
  border: 1px solid #454e99;
  color: #454e99;
}

.btn-save, .btn-prev {
  border: 0;
  background-color: #454e99;
  color: white;
}

.add-devis-custom {
  padding: 5px;
  border-radius: 5px;
  color: white;
}
.add-devis {
  background-color: #454e99;
}
.remove-devis {
  background-color: #e2162b;
}

.total-custom-style {
background-color: #f4f5f9;
color: #454e99;
padding: 15px;
display: flex;
justify-content: space-between;
border-radius: 5px;
font-size: 15px;
}

.total-custom-unpaid {
  background-color: #e2162b;
  color: #f4f5f9;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  font-size: 15px;
  }

  .total-custom-paid {
    background-color: #38c172;
    color: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 15px;
    }

.section-profil {
  position: relative;
  display: flex;
  justify-content: center;
  top: 150px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.description-text {
  margin-top: 10px;
  width: 88%;
  font-size: 15px;
}

.create-customer {
  display: flex;
flex-direction: column;
margin: auto;
align-items: center;
position: relative;
top: 50px;
font-weight: 100;
}
.btn--create-customer-modal {
  margin-top: 20px;
  height: 40px;
  width: 135px;
  border-radius: 5px;
  font-size: small;
}

.modal-config-action {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 20px;
}

.modal-dialog {
  padding: 10px;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  color: white;
}

.modal-config-title {
  margin: 16px 35px;
font-size: 20px;
font-weight: 200;
}

.modal-cancel, .btn--email {
  background-color: #93ccbf;
}
.modal-add, .btn--devis, .btn--acompte {
  background-color: #454e99;
}

.btn-historique {
  padding: 10px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
}

.label-color--mod {
  color: #93ccbf;
  font-size: 1.2rem;
  font-weight: bold;
}

.statut-presentation {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.btn-table-historique {
  background-color: #454e99;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 5px;
}

.preview-devis {
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15);
  padding: 30px;
  border-radius: 5px 0px 0px 5px;
  height: 100%;
  background-image: url('../../app/shared/assets/images/background-module.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 35px;
}

.preview-table__total--mod {
  border-bottom: 1px solid #f3f3f3;
  width: 155px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.total-table {
  width: 130px;
  text-align: end;
  padding: 5px;
  background-color: #378d7d;
  color: white;
  font-weight: bold;
}

.classification__Style {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 3px;
}

.section-card {
  border: 2px dashed #454e99;
  background-color: #f4f5f9;
  height: 100%;
}
.section-card--mod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  margin: 20rem 3rem;
}

.information--mod {
  background-color: #454e99;
  color: white;
  padding: 2px 10px;
  margin-bottom: 2rem;
}