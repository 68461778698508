._offres-header {
  text-align: center;
  font-size: 42px;
  padding-top: 10rem;
  padding-bottom: 25px;
  line-height: 40px;
  color: #454e99;
  width: 55rem;
  margin: auto;
}

._offres_card {
    opacity: 1;
    height: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000017;
    border-radius: 20px;
    padding: 15px 55px;

    ._offres_card_header {
      text-align: center;
      font-size: 20px;
      font-weight: bold;

      ._offres_card_header-montant {
        color: #52ae99;
        font-weight: 500;
      }
      ._offres_card_header-mois {
        font-size: 13px;
        font-weight: 300;      
      }
    }

    ._offres_card_main {
      ._offres_card_main-color {
        font-weight: bold;
      } 

      ._offres_card_main--mod {
        font-size: 15px;
        color: #52ae99;
      }
    }

    ._offres_card_footer {
      text-align: center;
      margin-top: 25px;
    }
}

@media only screen and (max-width: 959px) {
  ._offres-header {
      font-size: 34px;
      width: 100%;
  }
}
