.green-button {
  background: #52ae99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
}

.green-button:hover {
  background: #52ae99 0% 0% no-repeat padding-box !important;
}

.disabled-button-green{
  background: #52ae99 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border-radius: 5px !important;
  opacity: 0.5 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
}

.disable-green-button {
  background: #52ae99  0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border-radius: 5px !important;
  opacity: 0.5 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
}
.disabled-button-violet{
  background: #454e99  0% 0% no-repeat padding-box !important;
  color: #fff !important;
  border-radius: 5px !important;
  opacity: 0.5 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
}
.violet-button {
  background: #454e99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
  cursor: pointer;
}

.red-button {
  background: #ec0d0d 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
  cursor: pointer;
}

.violet-button:hover {
  background: #454e99 0% 0% no-repeat padding-box !important;
}

.violet-button-upload {
  background: #454e99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 400px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
  cursor: pointer;
}

.violet-button-upload-expense {
  background: #454e99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 100% !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: capitalize !important;
  color: #fff !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
  cursor: pointer;
  font-size: 16px !important;
}

.violet-button-upload:hover {
  background: #454e99 0% 0% no-repeat padding-box !important;
}

.violet-button-table {
  background: #454e99 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  color: #fff !important;
  font: normal normal normal 11px/15px Mada !important;
  width: 100% !important;
  height: 3rem;
}

.violet-button-table:hover {
  background: #454e99 0% 0% no-repeat padding-box !important;
}

.red-button-table {
  background: #ec0d0d 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  color: #fff !important;
  font: normal normal normal 11px/15px Mada !important;
  width: 100% !important;
  height: 3rem;
}

.red-button:hover {
  background: #ec0d0d 0% 0% no-repeat padding-box !important;
}

.violet-button-no-background {
  border: 1px solid #454e99 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 160px !important;
  height: 40px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #454e99 !important;
  margin: 1% !important;
  font: normal normal normal 11px/15px Mada !important;
}

.violet-button-modal {
  background: #454e99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 150px !important;
  height: 36px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  /* margin: 1% !important; */
  font: normal normal normal 11px/15px Mada !important;
}

.green-button-modal {
  background: #52ae99 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  width: 150px !important;
  height: 36px !important;
  outline-style: none !important;
  text-transform: none !important;
  color: #fff !important;
  /*   margin: 1% !important; */
  font: normal normal normal 11px/15px Mada !important;
}

.custom--button {
  border-radius: 5px;
  outline-style: none;
  text-transform: none;
  color: #fff;
  font: normal normal normal 11px/15px Mada;
  min-width: 100%;
  height: 100%;
  padding: 10px !important;
}

.btn--violent-transparent {
  color: #454e99 !important;
  border: 1px solid #454e99 !important;
}
.btn--green {
  color: white !important;
  background-color: #52ae99 !important;
}
.btn--violet {
  color: white !important;
  background-color: #454e99 !important;
}
.custom-status {
  padding: 1px;
  border-radius: 3px;
  font-size: 11px;
  color: #fff;
}
.status--encours {
  background-color: #17a2b8;
}
.status--actif {
  background-color: #28a745;
}
.status--bloque {
  background-color: #ec0d0d;
} 

.icon-button-wrapper {
  border: 1px solid black;
  color: #fff !important;
  height: 50px !important;
  width:20px !important;
}

.icon-button-purple{
  background-color: #454e99 !important;
  margin-right: 2px !important;
}
.icon-button-red{
  background-color:  #ec0d0d !important;
}
.icon-button-green{
  background-color:  #52AE99 !important;
}