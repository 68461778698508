/* OUTER CONTAINER */
.s-wrap {
  overflow: hidden;
  color: white;
  margin: auto;
  position: sticky;
  top: 45rem;
}

/* MIDDLE WRAPPER */
.s-move {
  display: flex;
  position: relative;
  top: 0;
  right: 0;
}

/* SLIDES */
.slide {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  flex-shrink: 0;
}

/* SLIDE ANIMATION MAGIC */
@keyframes slideh {
  0% {
    right: 0;
  }
  15% {
    right: 0;
  }
  20% {
    right: 100%;
  }
  35% {
    right: 100%;
  }
  40% {
    right: 200%;
  }
  55% {
    right: 200%;
  }
  60% {
    right: 300%;
  }
  75% {
    right: 300%;
  }
  80% {
    right: 400%;
  }
  95% {
    right: 400%;
  }
  100% {
    right: 0;
  }
}
.s-move {
  animation: slideh linear 25s infinite;
}
.s-move:hover {
  animation-play-state: paused;
}

.login-wrapper {
  display: flex;
  height: 100vh;
}

.left-part {
  flex: 1;
}
.left-part--mod {
  flex: 1;
}
.right-part--mod {
  flex: 1;
  text-align: center;
}

.right-part {
  flex: 2;
  text-align: center;
}

.login-connexion {
  margin: 45px;
}
