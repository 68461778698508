.tab-manager {
  padding: 24px;
  border-bottom: 1px solid #48484859;
}

.police {
  font-family: 'Mada';
}

.logo {
  width: 50px;
}

.returnBouton-style {
  color: white;
  background-color: #52ae99;
  width: 134px;
  height: 39px;
  border-radius: 3px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.returnContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.submitStyle {
  color: white;
  background-color: #454e99;
  width: 100%;
  height: 50px;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}
.submitStyle--mod {
  color: white;
  background-color: #454e99;
  width: 50%;
  height: 45px;
  border-radius: 5px;
}

.style {
  padding-right: 100px;
  font-size: 13px !important;
}

.paddingText {
  padding-right: 100px;
}

.paddingText1 {
  padding-right: 60px;
  font-size: 13px !important;
}

.password1 {
  margin-top: 30px;
}

input::placeholder {
  font-size: 13px;
}

.sizeAndBorder {
  border-radius: 7px;
}

input {
  font-size: 13px !important;
}

.password {
  margin-top: 20px;
}

.buttonConnexion1 {
  color: white;
  background-color: #454e99;
  width: 75%;
  border-radius: 7px !important;
  margin-top: 10px;
  text-align: center;
}

.tab {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  margin: 0 24px;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.5s ease;
  color: black;
  border: 1px solid #e1e1e1;
  height: 51px;
}

.tab:hover {
  background-color: rgba(0, 43, 73, 0.17);
}

.selected-tab {
  background-color: white;
  color: #454e99;
  border: 1px solid #454e99;
}

.bgImageGrille {
  background-image: url('../../app/shared/assets/images/login-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.userIcon {
  height: 50px;
  margin-right: 10px;
}

.meuble {
  height: 50px;
  margin-right: 10px;
}

.flexbox {
  display: flex;
  justify-content: space-around;
}

.size {
  width: 300px;
  padding: 15px;
  border-radius: 7px;
  height: 55px;
  margin-bottom: 25px !important;
}

.button3 {
  background-color: #454e99;
  height: 40px !important;
  width: 155px !important;
  margin-left: 140px !important;
  padding-right: 25px;
  border-radius: 7px !important;
}
