._mainHeader-left {
  position: relative;
  top: 20%;
  transform: translateY(20%);
  // margin: 0px 4rem;
  // padding: 10px;
  // width: 27rem;

  ._mainHeader-left-title {
    color: #52ae99;
    font-size: 50px;
    font-weight: 500;
  }

  ._mainHeader-left-subTitle {
    font-size: 41px;
    line-height: 1.1;
    font-weight: 300;
  }

  ._mainHeader-left-description {
    font-size: 20.5px;
    line-height: 1.1;
    font-weight: 300;
    margin-top: 20px;
  }

  ._mainHeader-left-button {
    margin-top: 35px;
  }
}

._mainHeader-right {
  background-image: url("../../app/shared/assets/images/background-newLanding.png");
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

@media only screen and (max-width: 959px) {
  ._mainHeader-left {
    position: relative;
    top: 60px;

    ._mainHeader-left-title {
      font-size: 37px;
    }

    ._mainHeader-left-subTitle {
      font-size: 30px;
    }
  }

  ._mainHeader-right {
    display: none;
  }
}
