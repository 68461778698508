.custom__modal__action {
  width: 290px;
  height: 375px;
  margin: auto 35px;

  .custom__modal__action-image {
    display: flex;
    justify-content: center;
    position: relative;
    top: 55px;
  }

  .custom__modal__content {
    position: relative;
    top: 80px;
    text-align: center;
    font-size: 13px;
    
    .custom__modal__content-title {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;
    }
  }

  .custom__modal__content__button {
    position: relative;
    top: 10rem;
    display: flex;
    justify-content: space-around;
  }
}
.custom__modal__Send {
  width: 410px;
  height: 350px;
  margin: 30px;

  .custom__modal__Send-title {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  .custom__modal__Send-image {
    text-align: center;
  }
}
.deleteCustomIcon {
  margin-left: 3rem;
  margin-right: -2rem;
  padding-right: 20px;
}
.wrapper {
  height: 350px;
  width: 440px;
  margin: auto 30px;

  .wrapper__content {
    position: relative;
    top: 25px;
  }
  .wrapper__actions {
    position: relative;
    top: 7rem;
    display: flex;
    justify-content: space-between;
  }
}

.wrapper-folder {
  height: 220px;
  width: 440px;
  margin: auto 30px;

  .wrapper-header {
    margin-top: 10px;
  }
}
