.import_job {
  border: 1px solid #bbbbbb;
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background: #f6f6f6 0% 0% no-repeat padding-box;

  &:hover {
    background: #ececec 0% 0% no-repeat padding-box;
  }
}

.dragAndDrop-style {
  border: 1px dashed black;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  margin: auto;
  height: 150px;
  background-color: ghostwhite;
}

.fileInput {
  display: none;
}

.fileLabel {
  background: #454e99 0% 0% no-repeat padding-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding: 10px;
  border-radius: 2px;
  width: 400px;
  color: white;
  font-size: 15px;
  margin: auto;
}

.fileLabelExpense {
  background: #454e99 0% 0% no-repeat padding-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding: 10px;
  border-radius: 2px;
  width: 100%;
  color: white;
  font-size: 15px;
}

.fileLabelAccountPlan {
  background: #454e99 0% 0% no-repeat padding-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  color: white;
  font-size: 15px;
  margin: auto;
}

.fileLabel:hover {
  background: #454e99 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 5px #7d7878;
  transition: 0.3s;
}
.title--mod {
  font-family: 'Mada', 'sans-serif';
  font-size: 30px;
  font-weight: 100;
}

.description--mod {
  font-size: 13px;
  font-weight: 100;
}
.color-text--mod {
  color: #2f8b7b;
}

.input-style--mod {
  border: 1px solid #bbbbbb;
  width: 37rem;
  height: 50px;
  padding: 10px;
  border-radius: 4px;
}
