.input-style {
  width: 100% !important;
  height: 50px;
  font-size: medium !important;
}

.input-style-select {
  border-radius: 18px;
  width: 100%;
  margin-top: 2.8%;
}

.input-style-select-expense {
  border-radius: 18px;
  width: 100%;
  // margin-top: 2.8%;
}

.input-style-select-countrie-code {
  border-radius: 18px;
  width: 95%;
  height: 50px;
}
.input-select-dropDownIcon {
  color: #2f8b7b;
}
