.dashboard-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 88%;
  width: 100%;
  margin: auto;
  text-align: center;
  transition: all 5s ease-out !important;
}
.permission-folder-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  margin: auto;
  text-align: center;
  transition: all 5s ease-out !important;
}
.manage-folder-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 88%;
  width: 100%;
  margin: auto;
  text-align: center;
  transition: all 1s ease-out;

  .manage-folder-root-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    margin-top: 22rem;
    font-size: 20px;

    .manage-folder-block1 {
      display: flex;
      flex-direction: column;
    }

    .manage-folder-block-text-container {
      display: flex !important;
      flex-direction: column !important;
      width: 55%;
      font: normal normal normal 40px/40px Mada !important;
      .manage-folder-block-text {
        font: normal normal normal 10px/15px Mada !important;
      }
    }
    .manage-folder-block-button {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 65%;
      margin: auto;
      margin-top: 2%;
    }
  }
}

.creation-folder-sidebar-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin: auto;
  box-shadow: -1px 0px 0px rgb(214, 214, 214);
  position:fixed;

  .sidebar-root-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 65%;
    padding: 5%;
    margin: auto;
    .side-bar-image-style {
      width: 150px;
      margin: auto;
    }
  }
}
.creation-folder-topbar-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .creation-folder-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: 90%;

    .creation-folder-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
      width: 100%;
      .header-create-folder {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        list-style: none;
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        .list-menu {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding-left: 0;
          cursor: pointer;
          transition: all 1s ease-out !important;
  
          .icon-style {
            display: none;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: rgb(82, 174, 153);
            margin-left:-5%;
          }
          .icon-style {
            display: block;
            animation-duration: 3s;
            animation-name: icon-style;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #52ae99;
            margin-left:-5%;
          }
          @keyframes icon-style {
            from {
              opacity: 0;
              transform: translateX(-100%);
            }
            to {
              opacity: 1;
              transform: translateX(0);
              transition: opacity 100ms, transform 100ms;
            }
          }

          a {
            list-style: none;
            color: inherit;
            text-decoration: none;
          }
          .text-focus {
            display:flex;
            justify-content:space-between;
            font: normal normal medium 35px/45px Mada;
            font-size:1.3em;
            letter-spacing: 0px !important;
            color: #454e99 !important;
            text-transform:capitalize;
            transition: all 1s ease-out !important;
           
            
          }
          .text-no-focus{
            text-transform:capitalize;
            font-size:1em;
          }
        }
      }
      .body-create-folder {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100% !important;
        margin-right: auto;
        margin-left: auto;
        margin-top: 1%;
      }
    }
    .creation-folder-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding:2em;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 7%;
      .create-folder-footer-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
  .creation-folder-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    width: 90%;
    margin-top: 4%;
    //padding:2em;
    margin-left: auto;
    margin-right: auto;
  }
}
.creation-folder-root {
  display: flex;
  padding-top: 2%;
  width: 100%;
  height: 100%;

  .create-folder-footer-container {
    width: 90% !important;
  }
}

.creation-folder-recapitulatif-root {
  .creation-folder-recapitulatif-container {
    display: flex;
    height: 100%;
    width: 100%;
    .creation-folder-recapitulation-header {
      height: auto;
      width: 100%;
    }
    .creation-folder-recapitulation-over-borderTop {
      font-size: 15px;
      margin-bottom: 2%;
    }
    .creation-folder-recapitulation-body {
      display: flex;
      width: 100%;
      min-height: 450px;
      border: 1px solid #dee0ed;
      padding: 2em;
      
      .creation-folder-title {
        color: #52ae99;
        font-size: 15px;
        margin-top: 0.8%;
      }
      .creation-folder-edite {
        color: #454e99;
        text-decoration: underline;
        cursor: pointer;
      }
      .creation-folder-edite:hover {
        color: #454e99;
        text-decoration: underline;
      }

      .creation-folder-text-container {
        margin-bottom: 1%;
        margin-top: 1%;

        .creation-folder-text-name {
          color: #777777;
          font-size: 12px;
        }
        .creation-folder-text-value {
          font-size: 12px;
          color: #444444;
          font-weight: 450;
        }
      }
      .creation-folder-recapitulation-body2 {
        border-bottom: 1px solid gray;
      }
    }
  }
}
