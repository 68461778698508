.btn {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  // margin: auto;
  justify-content: center;
  color: #fff;
}

.btn--draft, .btn--waiting {
  background: #e97c00 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--register {
  background: #0f9db4 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--send {
  background: #0fb436 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--accept {
  background: #0abc94 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--refuse {
  background: #bc0a0a 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--order {
  background: #6eac00 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--invoice {
  background: #047d28 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--paid {
  background: #4a8b7d 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--impaid {
  background: #c0c852 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--response, .btn--paying {
  background: #848a59 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}

.btn--cashiered {
  background: #6c9e57 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
.btn--uncashiered {
  background: #c0c852 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}

.btn--relaunch {
  background: #64689F 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}

.btn--waiting {
  background: #F6AB2D 0% 0% no-repeat padding-box !important;
  width:100px;
  color:white !important;
  height:30px;
  text-transform:capitalize !important;
}
